import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './Payment.css';
import axios from 'axios';
const tg = window.Telegram.WebApp;

const Payment = () => {
  const [showContacts, setShowContacts] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [id, setId] = useState(null);
  const [TG, setTG] = useState(false);
  const [VK, setVK] = useState(false);
  const [product, setProduct] = useState('1');
  const [currency, setCurrency] = useState('USD');
  const [email, setEmail] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('yookassa');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromURL = queryParams.get('id');
    if (idFromURL) {
      setId(Number(idFromURL));
      setVK(true);
    }
  }, []);

  const handleСurrencyChange = (val) => setCurrency(val);
  const handleShowContacts = () => setShowContacts(true);
  const handleCloseContacts = () => setShowContacts(false);
  const handleShowDescription = () => setShowDescription(true);
  const handleCloseDescription = () => setShowDescription(false);

  function selectButtonCurrency(id) {
    setCurrency(id);
  }

  useEffect(() => {
    if(tg.initDataUnsafe.user) {
      setTG(true);
      setId(tg.initDataUnsafe?.user?.id);
    }
  }, []);

  const handleSubmit = async (e) => {
    if(id == null || product === false) return alert('Что-то не так');
    e.preventDefault();
    if(paymentMethod === `vkpay`) {
      if(VK === false) {
        return window.open(`https://vk.com/write-177029630?ref=${id}&ref_source=3`, '_blank');
      } else {
        return window.open(`https://vk.com/iamgpt?source=description&w=donut_payment-177029630`, '_blank');
      }
    }
    try {
      const response = await axios.post(`https://pay.kraca.ru/${VK === true ? `apivk` : `api`}`, {
        method: 'create',
        product: product,
        id: id,
        email: email,
        paymentMethod: paymentMethod,
        currency: currency
      });
      
      if(response.data && response.data.payment_url) {
        return tg.openLink(`${response.data.payment_url}`);
      } else {
        if(response.data?.message == `Проверьте правильность введенного ID`) {
          const response = await axios.post(`https://pay.kraca.ru/apivk`, {
            method: 'create',
            product: product,
            id: id,
            email: email,
            paymentMethod: paymentMethod,
            currency: currency
          });
          
          if(response.data && response.data.payment_url) {
            return tg.openLink(`${response.data.payment_url}`);
            return window.open(`${response.data.payment_url}`, '_blank');
          } else {
            alert(response.data?.message ? response.data?.message :'Ошибка при создании платежа');
          }
        }
        alert(response.data?.message ? response.data?.message :'Ошибка при создании платежа');
      }
    } catch (error) {
      console.error('Error during payment creation:', error);
      alert('Ошибка при создании платежа');
    }
  };

  return (
    <div className="payment-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="order-md-1">
            <Card className="payment-card">
              <Card.Body>
                <Card.Title className="text-center">Оплата</Card.Title>
                <Form onSubmit={handleSubmit}>
                  {TG === false && VK === false && (
                  <Form.Group className="mb-3">
                    <Form.Label>Введите ID телеграм или ВК ссылку</Form.Label>
                    <Form.Control placeholder="Введите ID телеграм или ВК ссылку" defaultValue={id || ''} onChange={(e) => setId(e.target.value)} required />
                  </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Выберите тариф</Form.Label>
                    <Form.Control as="select" value={product} onChange={(e) => setProduct(e.target.value)}>
                      <>
                        <option value="premium" disabled>Тарифы:</option>
                        <option value="1">🌘Старт 1 месяц - 350 RUB</option>
                        <option value="2">🌗Мастер 1 месяц - 700 RUB</option>
                        <option value="10">⭐️ТОП мастер - 1.000 RUB</option>
                        <option value="3">🌕Годовой - 5.400 RUB</option>
                        <option value="7">🌞Beautystar Старт - 24.900 RUB</option>
                        <option value="8">🌞Beautystar Мастер - 39.900 RUB</option>
                        <option value="9">🌞Beautystar Предприниматель - 84.900 RUB</option>
                        {/* <option value="token" disabled>Докупить token</option>
                        <option value="4" >10 000 token - 159 RUB</option>
                        <option value="5" >20 000 token - 290 RUB</option>
                        <option value="6" >30 000 token - 420 RUB</option> */}
                      </>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Выберите способ оплаты</Form.Label>
                    <Form.Control as="select" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                      <option value="yookassa">Yookassa - (РФ) 0,5% комиссия</option>
                      {product !== '3' && product !== '0' && (
                        <option value="vkpay">VKPay - (РФ и СНГ) 0% комиссия</option>
                      )}
                      {product !== '0' && (
                        <option value="worldpay">Другие страны 0.5% комиссия</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                  {paymentMethod === `worldpay` && (
                    <Form.Group className="mb-3">
                      <Form.Label>Выберите валюту для оплаты:</Form.Label>
                      <ToggleButtonGroup
                        type="radio"
                        name="currency"
                        value={currency}
                        onChange={handleСurrencyChange}
                        className="w-100"
                      >
                        <ToggleButton variant="outline-primary" value="USD" onClick={() => selectButtonCurrency('USD')} className="me-2">USD</ToggleButton>
                        <ToggleButton variant="outline-primary" value="RUB" onClick={() => selectButtonCurrency('RUB')} className="me-2">RUB</ToggleButton>
                        <ToggleButton variant="outline-primary" value="EUR" onClick={() => selectButtonCurrency('EUR')}>EUR</ToggleButton>
                      </ToggleButtonGroup>
                    </Form.Group>
                  )}
                  {paymentMethod === `vkpay` && (
                    <Form.Group className="mb-3">
                      
                      Для активации подписи перейдите по вашей ссылке в VK_bot и нажмите кнопку "начать", либо отправьте слово "Оплатить" в диалог VK_bot по ссылке.
                      Далее выберите любой удобный способ оплаты.
                    </Form.Group>
                  )}
                  {paymentMethod !== `vkpay` && (
                  <Form.Group className="mb-3">
                    <Form.Label>Электронная почта (чек)</Form.Label>
                    <Form.Control type="email" placeholder="Введите почту" defaultValue={email || ''} onChange={(e) => setEmail(e.target.value)} required />
                  </Form.Group>
                  )}
                  <Button variant="primary" type="submit" className="w-100">Оплатить</Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="order-md-2 d-flex flex-column align-items-center justify-content-center mt-4 mt-md-0">
            <div className="button-group">
              <button className="custom-btn" onClick={handleShowDescription}>Описание товаров</button>
              <button className="custom-btn" onClick={handleShowContacts}>Контакты</button>
            </div>
            <div className="button-group">
              <Button className="custom-btn mt-3 user-agreement" href="https://sverhum.ru/page1.html" target="_blank">Пользовательское соглашение</Button>
              <Button className="custom-btn mt-3 user-agreement" href="https://t.me/dropsupport" target="_blank">Отменить продление</Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showContacts} onHide={handleCloseContacts}>
        <Modal.Header closeButton>
          <Modal.Title>Контакты</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='popup__inner-list'>
            <p className='popup__inner-main-title'>Связаться</p>
            <p className='popup__inner-item'>ИНН - 583509348538</p>
            <p className='popup__inner-item'>Почта: catkonfis@gmail.com</p>
            <p className='popup__inner-item'>TG: https://t.me/dropsupport</p>
          </ul>
        </Modal.Body>
      </Modal>

      <Modal show={showDescription} onHide={handleCloseDescription}>
        <Modal.Header closeButton>
          <Modal.Title>Описание товаров</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className='popup__inner-list'>
            <p className='popup__inner-main-title'>Авторские инструменты, шаблоны для социальных сетей и образования.</p>
            <p className='popup__inner-item'>Самая большая база шаблонов для мастеров и педагогов.</p>
            <p className='popup__inner-item'>В 2 клика прямо с тлф - создавайте  и используйте контент маркетинг (изображения и текст).</p>
            <p className='popup__inner-item'>Закрытый телеграм-канал с удобной навигацией по авторским шаблонам оформления социальных сетей и образовательных материалов.</p>
            <p className='popup__inner-main-title'>🌘Старт - руб 350/В месяц</p>
            <p className='popup__inner-item'>Доступ к нейро-созданию контента</p>
            <p className='popup__inner-main-title'>🌗Мастер - руб 700/В месяц</p>
            <p className='popup__inner-item'>Доступ к нейро-созданию контента + библиотека шаблонов</p>
            <p className='popup__inner-main-title'>⭐️ТОП мастер - 1.000 RUB/В месяц</p>
            <p className='popup__inner-item'>Доступ к нейро-созданию контента + библиотека шаблонов</p>
            <p className='popup__inner-main-title'>🌕Годовой -руб 5400/В год</p>
            <p className='popup__inner-item'>Все наполнение тарифа "Мастер" на год с выгодой 50%</p>
            <p className='popup__inner-main-title'>🌞Beautystar Старт- руб 24900</p>
            <p className='popup__inner-item'>Доступ к с-ме привлечения клиентов</p>
            <p className='popup__inner-main-title'>🌞Beautystar Старт- руб 39900</p>
            <p className='popup__inner-item'>Доступ к с-ме привлечения клиентов</p>
            <p className='popup__inner-main-title'>🌞Beautystar Старт- руб 84900</p>
            <p className='popup__inner-item'>Доступ к с-ме привлечения клиентов</p>
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Payment;
